@import '../../assets/scss/variables';

.wompi_button_widget {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;

  button {
    box-sizing: border-box;
    outline: none;
    background-color: $color-azul;
    color: $color-blanco;
    border: none;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    -webkit-font-smoothing: subpixel-antialiased;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    border-radius: 4px;
    padding: 0px 16px;
    transition: opacity 0.3s ease;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.9;
    }

    .wompi-wbutton-icon {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      vertical-align: middle;
      transform: translateY(-8%);
    }
  }
}
