// Colors
$color-blanco: #ffffff;
$color-negro: #000000;

$color-rojo: #bc2200;
$color-amarillo: #ffd200;
$color-azul: #07448e;
$color-azuloscuro: #00346b;
$color-gris: #b5b5b5;
$color-grisclaro: #f5f7f9;
$color-grisoscuro: #333333;
