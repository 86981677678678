@import '../../assets/scss/variables';

.wompi_button_widget {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;

  .wompi-wbox-modal-content-iframe {
    width: 100%;
    overflow: hidden;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    transition: height 0.4s ease;
    -webkit-transition: height 0.4s ease;
    -moz-transition: height 0.4s ease;
    -ms-transition: height 0.4s ease;
    -o-transition: height 0.4s ease;
  }

  button {
    box-sizing: border-box;
    outline: none;
    background-color: $color-azul;
    color: $color-blanco;
    border: none;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    -webkit-font-smoothing: subpixel-antialiased;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    border-radius: 4px;
    padding: 0px 16px;
    transition: opacity 0.3s ease;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.9;
    }

    .wompi-wbutton-icon {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      vertical-align: middle;
      transform: translateY(-8%);
    }
  }

  // Fondo negro con opacidad detrás del modal
  .wompi-wbox-backdrop {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow-y: scroll;
    background-color: rgba($color: $color-negro, $alpha: 0.8);
    opacity: 0;
    z-index: 63657;
    animation: 0.2s ease-out 0s 1 normal forwards running backdropFadeIn;
    -webkit-animation: 0.3s ease-out 0s 1 normal forwards running backdropFadeIn;
  }

  // Marco principal del modal
  .wompi-wbox-modal {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 3rem;
    min-width: 280px;
    width: 97%;
    max-width: 420px;
    box-shadow: rgba($color: $color-negro, $alpha: 0.6) 0px 0px 35px 0px;
    box-sizing: content-box;
    border-radius: 7px;
    background: $color-blanco;
    border-width: 0px;
    overflow: hidden;
    z-index: 63658;
    margin-bottom: 3rem;
  }

  // Encabezado del modal
  .wompi-wbox-modal-header {
    background-color: $color-grisclaro;
    position: relative;

    h1 {
      margin: 0;
      padding: 1rem;
      text-align: center;
      color: $color-azul;
      font-size: 1.15rem;
      line-height: 1.15rem;
    }

    .wompi-wbox-closebutton {
      background-color: $color-gris;
      padding: 0.65rem;
      display: inline-block;
      text-align: center;
      border-bottom-left-radius: 7px;
      position: absolute;
      top: 0px;
      right: 0px;

      &:hover {
        background-color: $color-rojo;
      }
      transition: background-color 0.3s ease;
      -webkit-transition: background-color 0.3s ease;
      -moz-transition: background-color 0.3s ease;
      -ms-transition: background-color 0.3s ease;
      -o-transition: background-color 0.3s ease;
    }
  }

  .wompi-wbox-modal-content {
    width: 100%;
    height: 100%;
  }

  .wompi-wbox-modal-footer {
    background-color: $color-grisclaro;
    padding: 0.7rem;
    font-size: 0.75rem;
    text-align: center;
    padding-top: 1rem;

    .wompi-wbox-modal-footer-logos {
      display: flex;
      justify-content: space-around;
      // align-items: center;
      flex-wrap: wrap;
    }

    p {
      margin: 0px;
      padding: 0px;
      color: $color-gris;
      margin-bottom: 1rem;
      font-weight: bold;
      line-height: 0.75rem;

      img {
        width: 100%;
        max-width: 125px;
      }
    }
  }

  .st0 {
    fill: $color-azul !important;
  }
}

@keyframes backdropFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
